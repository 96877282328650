var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "component-container elevation-10",
      style: { width: _vm.feedbackWidth }
    },
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c(
                "v-flex",
                {
                  staticClass: "mb-3",
                  staticStyle: { "text-align": "center" }
                },
                [
                  _c("span", { staticStyle: { "font-size": "large" } }, [
                    _vm._v("Feedback")
                  ])
                ]
              ),
              _c(
                "v-flex",
                { staticClass: "px-1 mb-2" },
                [
                  _c("span", { staticClass: "input-tips" }, [
                    _vm._v("How satified are you with our services?")
                  ]),
                  _c("v-rating", {
                    staticStyle: { "text-align": "center" },
                    model: {
                      value: _vm.formData.grade,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "grade", $$v)
                      },
                      expression: "formData.grade"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "px-1" },
                [
                  _c("span", { staticClass: "input-tips" }, [
                    _vm._v(
                      "Do you have any suggestions to improve our product or service?"
                    )
                  ]),
                  _c("v-textarea", {
                    staticStyle: { "margin-top": "10px" },
                    attrs: {
                      solo: "",
                      placeholder: "Suggestion description..."
                    },
                    model: {
                      value: _vm.formData.content,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "content", $$v)
                      },
                      expression: "formData.content"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "px-1 mb-2" },
                [
                  _c(
                    "Upload",
                    {
                      ref: "upload",
                      attrs: { size: _vm.limitSize.size, multiple: false },
                      model: {
                        value: _vm.files,
                        callback: function($$v) {
                          _vm.files = $$v
                        },
                        expression: "files"
                      }
                    },
                    [
                      _vm.files.length === 0
                        ? _c(
                            "button",
                            { staticClass: "upload-button" },
                            [
                              _vm._v(
                                "\n                        Click to upload files\n                        "
                              ),
                              _c("v-icon", { attrs: { right: "" } }, [
                                _vm._v("cloud_upload")
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.files.length !== 0
                        ? _c(
                            "button",
                            { staticClass: "upload-button" },
                            [
                              !_vm.files[0].active && _vm.files[0].success
                                ? [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.files[0].name) +
                                          " has been uploaded. Click to change it."
                                      )
                                    ])
                                  ]
                                : _vm._e(),
                              _vm.files[0].active && !_vm.files[0].success
                                ? [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        rotate: 360,
                                        size: 30,
                                        width: 5,
                                        value: _vm.files[0].progress,
                                        color: "teal"
                                      }
                                    }),
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(_vm.files[0].progress + "%") +
                                        "\n                        "
                                    )
                                  ]
                                : _vm._e(),
                              !_vm.files[0].active && !_vm.files[0].success
                                ? [
                                    _c(
                                      "span",
                                      {
                                        style: {
                                          color: _vm.$vuetify.theme.error
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.files[0].name) +
                                            "--" +
                                            _vm._s(
                                              _vm.files[0].error === "size"
                                                ? _vm.limitSize.errMsg
                                                : _vm.files[0].error
                                            ) +
                                            ",Click to re-upload."
                                        )
                                      ]
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "input-tips" },
                [
                  _vm._v("\n                Please "),
                  _c("router-link", { attrs: { to: "/ticket" } }, [
                    _vm._v("click here ")
                  ]),
                  _vm._v(
                    " to submit a ticket, if you have support and/or sale questions. We will respond within 1-2 hours.\n            "
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  staticClass: "px-1",
                  staticStyle: {
                    display: "inline-block",
                    "text-align": "right"
                  }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text text-none",
                      attrs: {
                        color: _vm.$vuetify.theme.primary,
                        loading: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          return _vm.sendFeedback()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    send\n                    "
                      ),
                      _c("v-icon", { attrs: { right: "", dark: "" } }, [
                        _vm._v("send")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }