<template>
    <v-container class="component-container elevation-10" :style="{width: feedbackWidth}">
        <v-form ref="form">
            <v-layout column>
                <v-flex class="mb-3" style="text-align: center">
                    <span style="font-size: large">Feedback</span>
                </v-flex>
                <v-flex class="px-1 mb-2">
                    <span class="input-tips">How satified are you with our services?</span>
                    <v-rating style="text-align: center"  v-model="formData.grade"></v-rating>
                </v-flex>
                <v-flex class="px-1">
                    <span class="input-tips">Do you have any suggestions to improve our product or service?</span>
                    <v-textarea solo
                                placeholder="Suggestion description..."
                                style="margin-top: 10px"
                                v-model="formData.content"></v-textarea>
                </v-flex>
                <v-flex class="px-1 mb-2">
                    <Upload :size="limitSize.size" v-model="files" ref="upload" :multiple="false">
                        <button v-if="files.length===0" class="upload-button">
                            Click to upload files
                            <v-icon right>cloud_upload</v-icon>
                        </button>
                        <button v-if="files.length!==0" class="upload-button">
                            <template v-if="!files[0].active && files[0].success">
                                <span>{{files[0].name}} has been uploaded. Click to change it.</span>
                            </template>
                            <template v-if="files[0].active && !files[0].success">
                                <v-progress-circular
                                        :rotate="360"
                                        :size="30"
                                        :width="5"
                                        :value="files[0].progress"
                                        color="teal">
                                </v-progress-circular>
                                {{ files[0].progress + '%' }}
                            </template>
                            <template v-if="!files[0].active && !files[0].success">
                                <span  :style="{color: $vuetify.theme.error}">{{files[0].name}}--{{files[0].error==='size'?limitSize.errMsg:files[0].error}},Click to re-upload.</span>
                            </template>
                        </button>
                    </Upload>
                </v-flex>
                <v-flex class="input-tips">
                    Please <router-link to="/ticket" >click here </router-link> to submit a ticket, if you have support and/or sale questions. We will respond within 1-2 hours.
                </v-flex>
                <v-flex class="px-1" style="display: inline-block;text-align: right">
                    <v-btn :color="$vuetify.theme.primary"
                           class="white--text text-none"
                           :loading="loading"
                           @click="sendFeedback()">
                        send
                        <v-icon right dark>send</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-form>
    </v-container>
</template>

<script>
    import Upload from '@/components/upload/Upload.vue'
    import {limitSize} from '@/module/constant.js'


    export default {
        name: "Feedback",
        components: {
            Upload
        },
        computed: {
            feedbackWidth () {
                if(this.$vuetify.breakpoint.name === 'xs') {
                    let w = document.body.clientWidth || document.documentElement.clientWidth ;
                    return `${w-32}px`
                } else {
                    return '400px'
                }
            }
        },
        data() {
            return {
                limitSize: limitSize,
                loading: false,
                files: [],
                formData: {
                    grade: null,
                    content: '',
                    attachments: []
                }
            }
        },
        methods: {
            sendFeedback() {
                if (!this.$refs.form.validate() || !this.$refs.upload.getUploaded()||!this.formData.grade) {
                    return
                }
                this.loading = true
                this.setAttachments()
                this.$http({
                    method: 'post',
                    url: '/feedbacks/',
                    data: this.formData
                }).then(() => {
                    this.$emit('sendSuccess')
                    this.loading = false
                    this.$message.success('Your feedback was submitted successfully. Thank you !')
                    this.resetData()
                }).catch(() => {
                    this.loading = false
                    this.$message.error('Oops, it failed to submit your feedback. Please contact us at support@cloudclusters.io for this issue')
                })
            },
            setAttachments() {
                this.formData.attachments = this.files.map(o => o.response.data)
            },
            resetData() {
                this.formData = {
                    grade: null,
                    content: '',
                    attachments: []
                }
                this.files = []
            }
        }
    }
</script>

<style lang="scss">
    .component-container {
        background-color: #fff;
        .upload-button {
            height: 50px;
            line-height: 50px;
            background-color: #ffffff;
            border: 1px lightgrey dashed;
            cursor: pointer;
            width: 100%;
        }
    }
    .v-file-upload {
        width: 100%;
    }
</style>
