var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-speed-dial", {
    staticClass: "float-wrapper",
    attrs: {
      bottom: "true",
      right: "true",
      direction: "top",
      transition: "slide-y-reverse-transition"
    },
    scopedSlots: _vm._u([
      {
        key: "activator",
        fn: function() {
          return [
            _c("transition", { attrs: { name: "fade" } }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.componentShow,
                      expression: "componentShow"
                    }
                  ],
                  staticClass: "feedback-wrapper",
                  class: { active: _vm.componentShow }
                },
                [
                  _c("Feedback", {
                    ref: "feedback",
                    on: {
                      sendSuccess: function($event) {
                        _vm.componentShow = false
                      }
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "v-btn",
              {
                attrs: {
                  id: "feedbackBtn",
                  color: _vm.$vuetify.theme.primary,
                  fab: "",
                  dark: ""
                },
                on: { click: _vm.changeComponent }
              },
              [
                _c("v-icon", [
                  _vm._v(_vm._s(!_vm.componentShow ? "feedback" : "close"))
                ])
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ]),
    model: {
      value: _vm.fab,
      callback: function($$v) {
        _vm.fab = $$v
      },
      expression: "fab"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }