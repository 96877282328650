<template>
    <v-speed-dial
            class="float-wrapper"
            v-model="fab"
            bottom="true"
            right="true"
            direction="top"
            transition="slide-y-reverse-transition">
        <template v-slot:activator>
            <transition name="fade">
                <div class="feedback-wrapper" v-show="componentShow" :class="{'active': componentShow}">
                    <Feedback ref="feedback" @sendSuccess="componentShow = false"/>
                </div>
            </transition>
            <v-btn
                    id="feedbackBtn"
                    :color="$vuetify.theme.primary"
                    fab
                    dark
                    @click="changeComponent">
                <v-icon>{{!componentShow?'feedback':'close'}}</v-icon>
            </v-btn>
        </template>
    </v-speed-dial>
</template>

<script>
    import Feedback from './Feedback.vue'

    export default {
        name: "Floatbutton",
        components: {Feedback},
        data() {
            return {
                flag: false,
                componentShow: false,
            }
        },
        watch: {
            componentShow(val) {
                if (!val) {
                    this.$refs.feedback.resetData()
                }
            }
        },
        methods: {
            changeComponent() {
                this.componentShow = !this.componentShow
            }
        },
    }
</script>

<style lang="scss">
    .float-wrapper {
        position: fixed;
        bottom: 50px !important;
        z-index: 2;
        .v-btn {
            position: relative;

            .v-icon {
                height: 24px !important;
            }
        }

        .feedback-wrapper {
            position: absolute;
            right: -500px;
            bottom: 85px;

            &.active {
                right: 0;
            }
        }
    }
</style>
